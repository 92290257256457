import { createElement, ElementType } from 'react'

import { Typography } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { SelectIcon } from '../../SelectIcon'

import classes from './QuickLink.module.scss'

export interface QuickLinkProps {
  as?: ElementType
  title: string
  className?: string
  highlight?: boolean
  [key: string]: unknown
  icon?: string
}

export interface ServiceQuickLinkProps extends QuickLinkProps {
  serviceKey: ServiceKey
}

const QuickLink = ({
  as,
  title,
  className,
  highlight,
  icon,
  ...otherProps
}: QuickLinkProps) =>
  createElement(
    as ?? 'a',
    {
      className: classes.QuickLink,
      ...otherProps
    },
    <>
      {icon && (
        <div className={classes.QuickLink__IconContainer}>
          <div className={classes.QuickLink__Icon}>{SelectIcon(icon)}</div>
        </div>
      )}
      <Typography format="bcm" as="h3" className={classes.QuickLink__Name}>
        {title}
      </Typography>
      {highlight && (
        <Typography format="bcm" className={classes.QuickLink__Highlight}>
          Preview
        </Typography>
      )}
    </>
  )

export default QuickLink

export const ServiceQuickLink = ({
  serviceKey,
  ...otherProps
}: ServiceQuickLinkProps) => {
  const serviceUrl = useServiceUrl(serviceKey, true)
  return <QuickLink {...otherProps} href={serviceUrl}></QuickLink>
}
