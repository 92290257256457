import { Field, Form, type FormikProps } from 'formik'

import { Typography, Button } from '@matillion/component-library'

import LearnMoreIcon from '../../../components/Icons/LearnMoreIcon'
import FormikFieldWrapper from 'components/FormikFieldWrapper'
import OrgAdmin from 'components/Icons/OrgAdmin'
import AdminPermissionField from './components/AdminPermissionField'
import PermissionsTable from './components/PermissionsTable'

import type { Member } from '../types'
import config from '../../../config'

import classes from './UserForm.module.scss'

export interface UserFormValues {
  id: string
  email: string
  hub: boolean
  permissions: {
    mdl: boolean
    agentmanager: boolean
    'platform-sje': boolean
    'saas-etl': boolean
    billing: boolean
  }
}

export type UserFormProps = {
  member?: Member
  onCancel: () => void
} & FormikProps<UserFormValues>

const UserForm = ({
  member,
  onCancel,
  isValid,
  isSubmitting
}: UserFormProps) => (
  <Form>
    {member ? (
      <div className={classes.UserForm__Row}>
        <Typography format="ts" as="h3">
          {member.firstName} {member.lastName}
        </Typography>

        <Typography format="bcm">{member.email}</Typography>
      </div>
    ) : (
      <Field
        className={classes.UserForm__Row}
        name="email"
        supportText={
          <Typography>
            Invitations can’t be sent to distribution lists
          </Typography>
        }
        title={<Typography>Email address</Typography>}
        component={FormikFieldWrapper}
      />
    )}

    <Typography
      format="ts"
      weight="bold"
      as="h4"
      className={classes.UserForm__Row}
    >
      Permissions
    </Typography>

    <Field
      className={classes.UserForm__Row}
      name="hub"
      type="checkbox"
      component={AdminPermissionField}
      title="User Admin"
      description="Can edit basic account settings and manage users."
      icon={<OrgAdmin />}
    />

    <Typography
      format="ts"
      weight="bold"
      as="h4"
      className={classes.UserForm__Row}
    >
      Services
    </Typography>

    <div className={classes.UserForm__Row}>
      <PermissionsTable />
      <div className={classes.UserForm__Hyperlink}>
        <a
          href={config.editPermissionsDocsUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Typography className={classes.UserForm__HyperlinkText}>
            Learn more
          </Typography>
          <LearnMoreIcon />
        </a>
      </div>
    </div>

    <div className={classes.ButtonGroup}>
      <Button alt="secondary" text="Cancel" size="lg" onClick={onCancel} />

      <Button
        disabled={!isValid || isSubmitting}
        waiting={isSubmitting}
        type="submit"
        alt="primary"
        size="lg"
        text={member ? 'Save' : 'Send Invite'}
      />
    </div>
  </Form>
)

export default UserForm
