import type { IconProps } from './types'

const ContextMenu = ({ className, ...otherProps }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    className={className}
    {...otherProps}
  >
    <rect
      y="28"
      width="28"
      height="28"
      rx="14"
      transform="rotate(-90 0 28)"
      fill="#F2F2F2"
    />
    <circle cx="6" cy="14" r="2" transform="rotate(-90 6 14)" fill="#222222" />
    <circle
      cx="14"
      cy="14"
      r="2"
      transform="rotate(-90 14 14)"
      fill="#222222"
    />
    <circle
      cx="22"
      cy="14"
      r="2"
      transform="rotate(-90 22 14)"
      fill="#222222"
    />
  </svg>
)

export default ContextMenu
