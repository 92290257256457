import './base.scss'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import {
  AuthProvider,
  FlagProvider,
  ServiceRegistryProvider
} from '@matillion/hub-client'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import config from './config'
import App from './App'
import { overrideXHRLogLevelForStatusCode } from './datadog'

if (config.datadog) {
  const { rumService, service, apiOrigin, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    beforeSend: overrideXHRLogLevelForStatusCode(0, 'warn', [
      'l.clarity.ms',
      'events.launchdarkly.com',
      'doubleclick.net'
    ])
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [apiOrigin]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const queryClient = new QueryClient()
const container = document.getElementById('root') as HTMLElement
container?.setAttribute('data-app-environment', config.environment)
container?.setAttribute('data-app-version', config.version)
const root = createRoot(container)

root.render(
  <StrictMode>
    <AuthProvider environment={config.authEnvironment}>
      <FlagProvider
        flags={config.launchDarkly.defaultFlags}
        launchDarklyClientId={config.launchDarkly.clientId}
      >
        <ServiceRegistryProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </BrowserRouter>
        </ServiceRegistryProvider>
      </FlagProvider>
    </AuthProvider>
  </StrictMode>
)
