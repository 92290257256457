import type { IconProps } from './types'

const Info = ({ className, ...otherProps }: IconProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...otherProps}
  >
    <circle cx="6" cy="6" r="6" fill="#6C6C6C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.5L7 9.5L7 6.5L5 6.5L5 9.5ZM6 2.5C5.17157 2.5 4.5 3.17157 4.5 4C4.5 4.82843 5.17157 5.5 6 5.5C6.82843 5.5 7.5 4.82843 7.5 4C7.5 3.17157 6.82843 2.5 6 2.5Z"
      fill="white"
    />
  </svg>
)

export default Info
