import { useContext, useMemo } from 'react'
import { Table as T, Typography } from '@matillion/component-library'

import { MembersContext } from '../context'
import MemberListItem from './MemberListItem'
import InvitationListItem from './InvitationListItem'

const { Table, TableHead, TableRow, TableCell, TableBody } = T

const MembersList = () => {
  const { members, invitations, isLoading } = useContext(MembersContext)

  const sortedMembers = useMemo(
    () =>
      members.sort((a, b) => {
        if (a.lastName && b.lastName) {
          return a.lastName.localeCompare(b.lastName)
        }

        if (a.lastName) {
          return -1
        }

        if (b.lastName) {
          return 1
        }

        return a.email.localeCompare(b.email)
      }),
    [members]
  )
  const sortedInvitations = useMemo(
    () => invitations.sort((a, b) => a.email.localeCompare(b.email)),
    [invitations]
  )

  return (
    <Table alt="light">
      <TableHead>
        <TableRow>
          <TableCell width="6">
            <Typography format="bcs" weight="bold">
              User
            </Typography>
          </TableCell>
          <TableCell width="6">
            <Typography format="bcs" weight="bold">
              Role
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell width="12">Loading...</TableCell>
          </TableRow>
        ) : (
          <>
            {sortedMembers.map((member) => (
              <MemberListItem key={member.id} {...member} />
            ))}
            {sortedInvitations.map((invitation) => (
              <InvitationListItem key={invitation.email} {...invitation} />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  )
}

export default MembersList
