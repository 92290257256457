import { useEffect } from 'react'

import { Loader } from '@matillion/component-library'
import { useAuth, useIntercom, usePendo } from '@matillion/hub-client'

const Logout = () => {
  const { logout } = useAuth()
  const pendo = usePendo()
  const { shutdown: intercomShutdown } = useIntercom()

  useEffect(() => {
    intercomShutdown()
    pendo.teardown()
    logout({ returnTo: window.location.origin })
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, pendo])

  return <Loader />
}

export default Logout
