import { type FieldProps } from 'formik'
import classnames from 'classnames'

import { Card, Toggle, Typography } from '@matillion/component-library'

import classes from './AdminPermissionField.module.scss'

interface AdminPermissionFieldProps extends FieldProps {
  title: React.ReactNode
  description: React.ReactNode
  icon: React.ReactNode
  className?: string
}

const AdminPermissionField = ({
  field,
  title,
  description,
  className
}: AdminPermissionFieldProps) => (
  <Card
    as="label"
    className={classnames(classes.AdminPermissionField, className)}
  >
    <div className={classes.AdminPermissionField__Text}>
      <Typography
        weight="bold"
        format="bcl"
        className={classes.AdminPermissionField__Title}
      >
        {title}
      </Typography>

      <Typography format="bcm" as="div">
        {description}
      </Typography>
    </div>

    <div>
      <Toggle {...field} icons />
    </div>
  </Card>
)

export default AdminPermissionField
