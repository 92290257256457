import { useCallback } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import classnames from 'classnames'
import {
  Modal,
  Toaster,
  Typography,
  Button
} from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import useRemoveRequest from '../effects/mutations/useRemoveRequest'
import useMember from '../effects/useMember'
import type { Member } from '../types'

import classes from '../Members.module.scss'

const getMemberName = (member?: Member) => {
  return member?.firstName && member.lastName
    ? `${member.firstName} ${member.lastName}`
    : member?.email
}

const RemoveMember = () => {
  const { makeToast } = Toaster.useToaster()
  const navigate = useNavigate()

  const [performRemoveRequest, { isLoading }] = useRemoveRequest()

  const { id } = useParams<{ id: string }>()
  const userId = id ?? ''
  const { organisation } = useUser()
  const member = useMember(userId)

  const returnToMembersList = useCallback(() => {
    navigate('/members')
  }, [navigate])

  const handleRemoveMemberRequest = useCallback(async () => {
    returnToMembersList()
    try {
      await performRemoveRequest({ id: userId })
      makeToast({
        title: 'User removed.',
        message: `${getMemberName(member) ?? 'User'} has been removed from ${
          organisation.name
        }.`,
        type: 'success'
      })
    } catch (e) {
      console.log('error', e)
      makeToast({
        title: 'Unable to remove.',
        message: `We weren't able to remove ${
          getMemberName(member) ?? 'User'
        } from ${organisation.name}. Please try again`,
        type: 'error'
      })
    }
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoading, performRemoveRequest, makeToast, returnToMembersList])

  if (!member) {
    return <Navigate to="/members" replace />
  }

  return (
    <Modal
      ariaLabelledBy="Members/Remove/Title"
      data-testid="Members/Remove"
      className={classes['Modal--medium']}
      size="default"
      onCancel={returnToMembersList}
    >
      <Typography
        id="Members/Remove/Title"
        className={classnames(classes.Modal__Title, classes.Modal__Row)}
        format="tl"
        as="h3"
      >
        Remove User
      </Typography>

      <Typography as="div" format="bcm" className={classes.Modal__Row}>
        You&apos;re about to remove{' '}
        <Typography as="span" weight="bold" format="bcm">
          {getMemberName(member) ?? 'User'}
        </Typography>{' '}
        from{' '}
        <Typography as="span" weight="bold" format="bcm">
          {organisation.name}
        </Typography>
        . If you change your mind, you can re-invite them to the account.{' '}
      </Typography>

      <div className={classes.ButtonGroup}>
        <Button
          alt="secondary"
          size="lg"
          type="button"
          text="Cancel"
          onClick={returnToMembersList}
        />

        <Button
          alt="danger"
          onClick={() => {
            handleRemoveMemberRequest()
          }}
          size="lg"
          text="Remove"
          type="button"
          waiting={isLoading}
          disabled={isLoading}
        />
      </div>
    </Modal>
  )
}

export default RemoveMember
