import { Table as T, Typography } from '@matillion/component-library'
import { useFlags, useEntitlements } from '@matillion/hub-client'

import PermissionsTableRow from './PermissionsTableRow'

import classes from './PermissionsTable.module.scss'

const { Table, TableHead, TableBody, TableRow, TableCell } = T

const PermissionsTable = () => {
  const flags = useFlags()
  const { data: entitlements } = useEntitlements()
  const canAccessDesigner = entitlements?.canAccessDesigner

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.PermissionsTableHeaderRow}>
          <TableCell width="6" className={classes.PermissionsTableHeader}>
            <Typography format="bcs">Service</Typography>
          </TableCell>
          <TableCell
            width="5"
            align="right"
            className={classes.PermissionsTableHeader}
          >
            <Typography format="bcs">Access</Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {canAccessDesigner && (
          <PermissionsTableRow
            name="saas-etl"
            title={<Typography>Designer</Typography>}
          />
        )}

        <PermissionsTableRow
          name="mdl"
          title={<Typography>Data Loader</Typography>}
        />

        <PermissionsTableRow
          name="agentmanager"
          title={<Typography>Manage Agents</Typography>}
        />

        <PermissionsTableRow
          name="billing"
          title={<Typography>Billing</Typography>}
        />

        {!!flags.enableUnlimitedScalePermissions && (
          <PermissionsTableRow
            name="platform-sje"
            title={<Typography>Unlimited Scale</Typography>}
          />
        )}
      </TableBody>
    </Table>
  )
}

export default PermissionsTable
