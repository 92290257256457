import { useFlags, useText } from '@matillion/hub-client'
import { AnnouncementRibbon, Prose } from '@matillion/component-library'

interface Flags {
  showAnnouncement: string
}
interface AnnouncementForFlagProps {
  flagValue: string
}

const AnnouncementForFlag = ({ flagValue }: AnnouncementForFlagProps) => {
  const { data, isSuccess } = useText(flagValue + '.md')
  if (!isSuccess) {
    return null
  }
  return (
    <AnnouncementRibbon data-testid={'announcement-ribbon'}>
      <Prose baseTypography="bcm">{data as string}</Prose>
    </AnnouncementRibbon>
  )
}

export const Announcement = () => {
  const flags = useFlags<Flags>()
  const { showAnnouncement } = flags
  if (!showAnnouncement) {
    return null
  }
  return <AnnouncementForFlag flagValue={showAnnouncement} />
}
