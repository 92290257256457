import { useMutation, useQueryClient } from 'react-query'
import { createCredentials } from 'api/userIdentityApi'
import type { Credential } from '../../types'

const CREDENTIALS_QUERY_KEY = 'api-credentials'

interface UseCreateCredentialsPayload {
  orgId: string
  name: string
}

interface UseCreateCredentialsResponse {
  isSuccess: boolean
  data?: Credential
  error?: unknown
  createCredentials: (
    request: UseCreateCredentialsPayload
  ) => Promise<Credential>
}

const useCreateCredentials = (): UseCreateCredentialsResponse => {
  const queryClient = useQueryClient()
  const createCredentialsMutation = useMutation(createCredentials)

  const handleCreateCredentials = async (
    request: UseCreateCredentialsPayload
  ): Promise<Credential> => {
    const credentials = await createCredentialsMutation.mutateAsync(request)
    const credentialWithoutSecret = {
      ...credentials,
      clientSecret: null
    }
    queryClient.setQueryData(
      [CREDENTIALS_QUERY_KEY, request.orgId],
      () => credentialWithoutSecret
    )
    return credentials
  }

  return {
    isSuccess: createCredentialsMutation.isSuccess,
    error: createCredentialsMutation.error,
    data: createCredentialsMutation.data,
    createCredentials: handleCreateCredentials
  }
}

export default useCreateCredentials
