import { useEffect, useState } from 'react'
import type { Credential } from '../types'
import useMembersQuery from '../../../Members/effects/useMembersQuery'
import {
  LoadingSpinner,
  MicroCta,
  Table as T,
  Toaster,
  Typography
} from '@matillion/component-library'
import Delete from '../../../../components/Icons/Delete'
import classNames from './ActiveCredentials.module.scss'
import convertDateToAge from './ConvertDateToAge'
import Info from '../../../../components/Icons/Info'
import getMessageFromError from '../../util/getMessageFromError'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import useDeleteCredential from '../effects/mutations/useDeleteCredential'
import { useUser } from '@matillion/hub-client'
const { Table, TableHead, TableBody, TableRow, TableHeaderCell, TableCell } = T

interface ActiveCredentialsProps {
  credentials: Credential
}

interface MemberName {
  id: string
  firstName: string
  lastName: string
}
type UserAction = 'delete' // Will expand to include rotate etc. as a union type

const ActiveCredentials = ({ credentials }: ActiveCredentialsProps) => {
  const { makeToast } = Toaster.useToaster()
  const { isLoading, error, members } = useMembersQuery()
  const [confirmAction, setConfirmAction] = useState<undefined | UserAction>(
    undefined
  )
  const { organisation } = useUser()
  const performDeleteCredential = useDeleteCredential(organisation.id)

  const confirmDeleteModalConfig = {
    title: 'Delete API Credential',
    warning:
      'If you delete this API credential, you might break integration with API consumers using it. This action is not recoverable.',
    confirmButtonText: 'Delete'
  }

  const handleDeleteCredentials = async () => {
    return performDeleteCredential()
      .then(() => {
        makeToast({
          title: 'API Credential deleted',
          message: 'The API credential has been deleted',
          type: 'success'
        })
      })
      .catch((e: unknown) => {
        const errorMessage = getMessageFromError(e)
        makeToast({
          title: 'API Credential could not be deleted',
          message: `${
            errorMessage ? errorMessage + '. ' : ''
          }Please try again later.`,
          type: 'error'
        })
      })
      .finally(() => setConfirmAction(undefined))
  }

  const getConfirmActionModal = () => {
    if (confirmAction === 'delete') {
      return (
        <ConfirmModal
          config={confirmDeleteModalConfig}
          onConfirm={handleDeleteCredentials}
          onClose={() => setConfirmAction(undefined)}
        />
      )
    }
  }

  useEffect(() => {
    if (error) {
      const message = getMessageFromError(error)
      makeToast({
        title: "We couldn't load some of the credential details",
        message: `${message ? message + '. ' : ''}Please try again later.`,
        type: 'error'
      })
    }
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleCopySuccess = () => {
    makeToast({
      title: 'Client ID copied',
      message: 'The client ID has been attached to your clipboard',
      type: 'success'
    })
  }

  const handleCopyError = (errorMessage: string) => {
    makeToast({
      title: 'The Client ID was not copied',
      message: errorMessage,
      type: 'error'
    })
  }

  const buildRowFromCredential = (
    credenital: Credential,
    memberNames: MemberName[]
  ) => {
    const creatorUser = memberNames?.find((m) => m.id === credentials.userId)
    const creatorFullName = creatorUser
      ? `${creatorUser.firstName} ${creatorUser.lastName}`
      : ''
    const lastModifiedDate = new Date(credenital.lastModified)
    return (
      <TableRow className={classNames.TableRow}>
        <TableCell width="3" align="left">
          <Typography
            format={'bcm'}
            className={classNames.Typography__WordBreak}
          >
            {credenital.name}
          </Typography>
        </TableCell>
        <TableCell width="3" align="left">
          <Typography
            format={'bcm'}
            className={classNames.Typography__WordBreak}
          >
            {credenital.clientId}
          </Typography>
          <CopyToClipboard
            className={classNames.CopyToClipboard}
            value={credenital.clientId}
            colour={'black'}
            onSuccess={handleCopySuccess}
            onError={handleCopyError}
          />
        </TableCell>
        <TableCell width="2" align="left">
          <div className={classNames.ClientSecretAgeGroup}>
            <Typography format={'bcm'}>
              {convertDateToAge(lastModifiedDate)}
            </Typography>
            <MicroCta
              alt="transparent"
              aria-label={`Last updated: ${
                lastModifiedDate.toDateString() +
                ' ' +
                lastModifiedDate.toTimeString()
              }`}
              tooltipProps={{
                padding: 5
              }}
            >
              <Info />
            </MicroCta>
          </div>
        </TableCell>
        <TableCell width="2" align="left">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Typography format={'bcm'}>{creatorFullName}</Typography>
          )}
        </TableCell>
        <TableCell width="2" align="left">
          <div className={classNames.UserActionBtnGroup}>
            <MicroCta
              alt="transparent"
              aria-label="Delete"
              className={classNames.DeleteCredentialsButton}
              data-testid="delete-creds-btn"
              tooltipProps={{
                padding: 5
              }}
              onClick={() => setConfirmAction('delete')}
            >
              <Delete />
            </MicroCta>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {confirmAction && getConfirmActionModal()}
      <Table aria-label="Table Title">
        <TableHead>
          <TableRow className={classNames.TableRow}>
            <TableHeaderCell width="3" align="left">
              <Typography format="bcs">Name</Typography>
            </TableHeaderCell>
            <TableHeaderCell width="3" align="left">
              <Typography format="bcs">Client ID</Typography>
            </TableHeaderCell>
            <TableHeaderCell width="2" align="left">
              <Typography format="bcs">Client secret age</Typography>
            </TableHeaderCell>
            <TableHeaderCell width="2" align="left">
              <Typography format="bcs">Created by</Typography>
            </TableHeaderCell>
            <TableHeaderCell width="2" align="left">
              {/* No header text for user actions */}
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>{buildRowFromCredential(credentials, members)}</TableBody>
      </Table>
    </>
  )
}

export default ActiveCredentials
