import { ReactNode } from 'react'
import { Table as T, Toggle } from '@matillion/component-library'
import { Field } from 'formik'

import FormikFieldWrapper from 'components/FormikFieldWrapper'

const { TableRow, TableCell } = T

interface AppPermissionFieldProps {
  name: string
  title: ReactNode
  className?: string
}

const PermissionsTableRow = ({
  name,
  title,
  className
}: AppPermissionFieldProps) => (
  <TableRow className={className}>
    <TableCell width="6">{title}</TableCell>
    <TableCell width="5" align="right">
      <Field
        name={`permissions.${name}`}
        data-testid={`Permissions/${name}/Access`}
        type="checkbox"
        component={FormikFieldWrapper}
        inputComponent={Toggle}
        icons
      />
    </TableCell>
  </TableRow>
)

export default PermissionsTableRow
