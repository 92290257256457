import { Button, Typography } from '@matillion/component-library'

import Connection from '../../../../components/Icons/Connection'

import classes from './NoCredentialsDialog.module.scss'

interface NoCredentialsDialogProps {
  onCreateCredential: () => void
}

const NoCredentialsDialog = ({
  onCreateCredential
}: NoCredentialsDialogProps) => (
  <div className={classes.NoCredentialsPrompt}>
    <Connection />
    <Typography format={'dts'}>
      You haven’t set any API credentials for the moment
    </Typography>
    <Typography format={'bcm'}>
      To start using Matillion API’s, you have to set an API credential that
      will be linked to your account and will enable you to make your first API
      calls
    </Typography>
    <Button
      className={classes.CreateCredentialButton}
      onClick={onCreateCredential}
    >
      <Typography format={'bcm'} weight={'bold'}>
        Set an API Credential
      </Typography>
    </Button>
  </div>
)

export default NoCredentialsDialog
