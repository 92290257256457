import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { removeUser } from 'api/hub'

import type { Member } from '../../types'

const MEMBERS_QUERY_KEY = 'members'

interface UseRemoveRequestPayload {
  id: string
}

const useRemoveMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(removeUser, {
    onMutate: async (removedMember) => {
      await queryClient.cancelQueries(MEMBERS_QUERY_KEY)

      const previousResult =
        queryClient.getQueryData<Member[]>(MEMBERS_QUERY_KEY) ?? []

      queryClient.setQueryData(MEMBERS_QUERY_KEY, () =>
        previousResult.filter((member) => member.id !== removedMember.member_id)
      )

      return previousResult
    },
    onError: (_, __, previousResult) => {
      queryClient.setQueryData(MEMBERS_QUERY_KEY, previousResult)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MEMBERS_QUERY_KEY)
    }
  })
}

const useRemoveRequest = () => {
  const { mutateAsync, ...rest } = useRemoveMutation()

  const performRemoveRequest = useCallback(
    async ({ id }: UseRemoveRequestPayload) => {
      try {
        await mutateAsync({
          member_id: id
        })
      } catch (e) {
        console.error('Could not update user!', e)
        throw e
      }
    },
    [mutateAsync]
  )

  return [performRemoveRequest, rest] as const
}

export default useRemoveRequest
