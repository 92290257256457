import { AppMenu, AppMenuItem, Icon } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import MenuNavLink from 'components/MenuNavLink'

const AppMenuComponent = () => {
  const { roles } = useUser()
  const isAdmin = roles.has('hub:admin')

  return (
    <AppMenu>
      <MenuNavLink
        key="home"
        as={AppMenuItem}
        to="/"
        name="Home"
        icon={<Icon.World />}
      />
      <MenuNavLink
        key="users"
        as={AppMenuItem}
        to="/members"
        name="Users"
        icon={<Icon.Group />}
      />
      <>
        {isAdmin && (
          <MenuNavLink
            key="manage"
            as={AppMenuItem}
            to="/manage"
            name="Manage"
            icon={<Icon.Cog />}
          />
        )}
      </>
    </AppMenu>
  )
}

export default AppMenuComponent
