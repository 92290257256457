import { useEffect } from 'react'

import { Loader } from '@matillion/component-library'
import { useAuth } from '@matillion/hub-client'

const Login = () => {
  const { login } = useAuth()

  useEffect(() => {
    login({
      /*
       * billing-login is the default Matillion Hub login screen,
       * but this can be changed on a per-app basis
       */
      pageBranding: 'billing-login'
    })
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loader />
}

export default Login
