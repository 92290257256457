import { Table as T } from '@matillion/component-library'

import type { Invitation } from '../types'
import MemberProfile from './MemberProfile'

const { TableRow, TableCell } = T

const InvitationListItem = ({ email }: Invitation) => (
  <TableRow>
    <TableCell width="12">
      <MemberProfile email={email} />
    </TableCell>
  </TableRow>
)

export default InvitationListItem
