import { AuthProviderEnvironment } from '@matillion/hub-client'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'

export const getEnvFromUrl = (raw: string) => {
  const hostname = new URL(raw).hostname
    .replace('.eu1.', '.')
    .replace('.us1.', '.')
  const currentEnv = hostname
    .split('.')
    ?.reverse()?.[2]
    ?.split('-')
    ?.reverse()?.[0]
  if (['local', 'test', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}
const environment =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? process.env.REACT_APP_ENVIRONMENT
    : getEnvFromUrl(window.location.href)

interface EnvironmentConfig {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  HUB_URL: string
  HUB_API: string
  USER_IDENTITY_SERVICE_API: string
  DESIGNER_SPRING_API_URL: string
  DESIGNER_SPRING_API_AUDIENCE: string
  EDIT_PERMISSIONS_DOCS_URL: string

  SERVICES: {
    BILLING_URL: string
    DATALOADER_URL: string
    ETL_AGENTS_URL: string
    COMMUNITY_URL: string
    ACADEMY_URL: string
    DEVELOPER_RELATIONS_URL: string
    CYOC_URL: string
    OBSERVABILITY_DASHBOARD_URL: string
    PROJECT_EXPLORER_URL: string
    SUPPORT_URL: string
  }

  GTM_ID?: string
  DATADOG?: Record<string, string>

  LAUNCHDARKLY?: {
    CLIENT_ID?: string
    DEFAULT_FLAGS?: Record<string, boolean | string>
  }
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

const config = {
  environment,
  version,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  hubUrl: envConfig.HUB_URL,
  hubApi: envConfig.HUB_API,
  userIdentityServiceApi: envConfig.USER_IDENTITY_SERVICE_API,
  designerSpringApiUrl: envConfig.DESIGNER_SPRING_API_URL,
  designerSpringApiAudience: envConfig.DESIGNER_SPRING_API_AUDIENCE,
  editPermissionsDocsUrl: envConfig.EDIT_PERMISSIONS_DOCS_URL,

  gtmId: envConfig.GTM_ID,

  communityUrl: envConfig.SERVICES.COMMUNITY_URL,
  academyUrl: envConfig.SERVICES.ACADEMY_URL,
  supportUrl: envConfig.SERVICES.SUPPORT_URL,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: 'datadoghq.eu',
    clientToken: 'pubf80e40f437efac6b4819a027d0ec679c',
    service: 'start-frontend',
    rumService: 'start-frontend',
    applicationId: '8cd41333-a49d-40bf-a330-851d24d2a8f7',
    apiOrigin: envConfig.DATADOG.API_ORIGIN
  },

  launchDarkly: {
    clientId: envConfig.LAUNCHDARKLY?.CLIENT_ID,
    defaultFlags: envConfig.LAUNCHDARKLY?.DEFAULT_FLAGS ?? {}
  }
}
if (process.env.NODE_ENV !== 'production') {
  config.launchDarkly.defaultFlags = Object.entries(
    config.launchDarkly.defaultFlags
  ).reduce((obj, [key, defaultValue]) => {
    const override = window.localStorage.getItem(`flag.${key}`)
    return {
      ...obj,
      [key]: override ? override === 'true' : defaultValue
    }
  }, {})
}

export default config
