import { Layout, Page, Toaster } from '@matillion/component-library'
import { HubHeader } from '@matillion/hub-client'
import {
  TrialBanner,
  TrialBannerEnvironment
} from '@matillion/billing-frontend-sdk'

import AppMenu from './AppMenu'
import { Announcement } from '../Announcement'

import config from 'config'
import type { ChildrenProps } from 'types'

const AppLayout = ({ children }: ChildrenProps) => {
  const bannerEnv =
    TrialBannerEnvironment[
      config.environment as keyof typeof TrialBannerEnvironment
    ]

  return (
    <Layout header={<HubHeader />} appMenu={<AppMenu />}>
      <Toaster.Provider theme="dark" align="center" withFade>
        <Page>
          <TrialBanner environment={bannerEnv} />
          <Announcement />
          {children}
        </Page>
      </Toaster.Provider>
    </Layout>
  )
}

export default AppLayout
