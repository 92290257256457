import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  useAuth,
  useIntercom,
  useUser,
  usePendo,
  useSendLDExperimentAnalytics,
  heap,
  useFlags
} from '@matillion/hub-client'

import hubApi from 'api/hub'
import userIdentityApi from 'api/userIdentityApi'
import useAuthInterceptor from 'effects/useAuthInterceptor'

import AppLayout from './components/AppLayout'
import { Login, Logout } from './modules/Auth'
import Dashboard from './modules/Dashboard'
import Members from './modules/Members'
import Manage from './modules/Manage'

const App = () => {
  useAuthInterceptor(hubApi)
  useAuthInterceptor(userIdentityApi)
  useIntercom()
  const { user, profile, organisation } = useUser()
  const pendo = usePendo()
  const { enableTrialExpiredExperienceExperiment } = useFlags()

  useEffect(() => {
    pendo.initialize(user.sub, organisation.id)
  }, [user, organisation, pendo])

  useEffect(() => {
    pendo.updateOptions(
      {
        email: user.email,
        full_name: profile.name
      },
      {
        name: organisation.name,
        subdomain: organisation.subdomain,
        region: organisation.region
      }
    )
  }, [user, profile, organisation, pendo])

  useEffect(() => {
    // TODO: https://matillion.atlassian.net/browse/DPC-15333
    if (!window.__MICROVERSE__) {
      heap.clearEventProperties()
    }
  }, [])

  useSendLDExperimentAnalytics([
    {
      experimentName: 'Hub Dashboard - Trial Expired Experience',
      flagKey: 'enable-trial-expired-experience-experiment',
      variationName: enableTrialExpiredExperienceExperiment
        ? 'Variation A - Show Tile & Modal'
        : 'Control - No Changes'
    }
  ])

  return (
    <AppLayout>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/members/*" element={<Members />} />
        <Route path="/manage/*" element={<Manage />} />
        <Route path="/dashboard" element={<Navigate to="/" replace />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </AppLayout>
  )
}

const AppWithLogin = () => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn) {
    return <Login />
  }

  return <App />
}

export default AppWithLogin
