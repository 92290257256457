import { Field, Form, type FormikProps } from 'formik'
import { Button, Typography } from '@matillion/component-library'

import FormikFieldWrapper from 'components/FormikFieldWrapper'

import classes from './CreateCredentialsForm.module.scss'

export interface CreateCredentialsFormValues {
  credentialsName: string
}

const CreateCredentialsForm = ({
  isValid,
  isSubmitting
}: FormikProps<CreateCredentialsFormValues>) => (
  <Form className={classes.FormContainer}>
    <Typography className={classes.FormContainer__Title} format={'tl'} as="h3">
      Add new API credential
    </Typography>

    <Field
      className={classes.FormContainer__NameField}
      name="credentialsName"
      supportText={
        <Typography format={'bcm'}>
          Identify the app associated with this API credential
        </Typography>
      }
      title="Name"
      component={FormikFieldWrapper}
      data-testid="credentials-name-input"
    />
    <Button
      disabled={!isValid || isSubmitting}
      waiting={isSubmitting}
      className={classes.FormContainer__SaveButton}
      type="submit"
      text={'Save'}
      alt="positive"
    />
  </Form>
)

export default CreateCredentialsForm
