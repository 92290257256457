import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import {
  Grid,
  Section,
  SidebarLayout,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuItemGroup
} from '@matillion/component-library'
import { RoleGuard } from '@matillion/hub-client'

import ManageAPICredentials from './ManageAPICredentials'
import ManageAccountDetails from './ManageAccountDetails'

const Manage = () => {
  const location = useLocation()

  const managePath = '/manage'
  const accountDetailsPath = '/account-details'
  const apiCredentialsPath = '/api-credentials'

  return (
    <RoleGuard role={'hub:admin'}>
      <SidebarLayout
        sidebar={
          <SidebarMenu>
            <SidebarMenuItemGroup>
              <SidebarMenuItem
                active={location.pathname === managePath + accountDetailsPath}
                as={Link}
                to={managePath + accountDetailsPath}
              >
                Account details
              </SidebarMenuItem>
              <SidebarMenuItem
                active={location.pathname === managePath + apiCredentialsPath}
                as={Link}
                to={managePath + apiCredentialsPath}
              >
                API credentials
              </SidebarMenuItem>
            </SidebarMenuItemGroup>
          </SidebarMenu>
        }
      >
        <Grid>
          <Section>
            <Routes>
              <Route
                path={accountDetailsPath}
                element={<ManageAccountDetails />}
              />
              <Route
                path={apiCredentialsPath}
                element={<ManageAPICredentials />}
              />
              <Route
                path="*"
                element={
                  <Navigate to={managePath + accountDetailsPath} replace />
                }
              />
            </Routes>
          </Section>
        </Grid>
      </SidebarLayout>
    </RoleGuard>
  )
}

export default Manage
