import { createElement, ElementType } from 'react'
import classnames from 'classnames'

import { ServiceKey, useServiceUrl } from '@matillion/hub-client'
import { Typography } from '@matillion/component-library'

import { SelectIcon } from '../../SelectIcon'

import classes from './Destination.module.scss'

export interface DestinationProps {
  as?: ElementType
  title?: string
  description: string
  className?: string
  label?: {
    text: string
    colour?: string
  }
  [key: string]: unknown
  icon?: string
  large?: boolean
  logo?: string
  href?: string
  onClick?: () => void
}

export interface ServiceDestinationProps extends DestinationProps {
  serviceKey: ServiceKey
}

const Destination = ({
  as,
  title,
  description,
  className,
  label,
  icon,
  large,
  logo,
  onClick,
  href,
  ...otherProps
}: DestinationProps) => {
  const content = (
    <>
      {label && (
        <Typography
          format="bcs"
          className={classnames(classes.Destination__Label, className, {
            [classes['Destination__Label-Grey']]: label.colour === 'grey'
          })}
        >
          {label.text}
        </Typography>
      )}
      {icon && (
        <div
          className={classnames(classes.Destination__Icon, className, {
            [classes['Destination__Icon-Small']]: !large,
            [classes['Destination__Icon-Large']]: large
          })}
        >
          {SelectIcon(icon)}
        </div>
      )}
      {logo && (
        <div className={classes.Destination__Logo}>{SelectIcon(logo)}</div>
      )}
      {title && (
        <Typography format="tm" as="h3" className={classes.Destination__Name}>
          {title}
        </Typography>
      )}

      <Typography format="bcm" className={classes.Destination__Description}>
        {description}
      </Typography>
    </>
  )

  return createElement(
    as ?? 'a',
    {
      className: classnames(classes.Destination),
      ...(onClick ? { onClick } : { href }),
      ...otherProps
    },
    content
  )
}

export default Destination

export const ServiceDestination = ({
  serviceKey,
  ...otherProps
}: ServiceDestinationProps) => {
  const serviceUrl = useServiceUrl(serviceKey, true)
  return (
    <Destination
      {...otherProps}
      href={otherProps.onClick ? undefined : serviceUrl}
    ></Destination>
  )
}
