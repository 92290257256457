import { useState } from 'react'
import classNames from 'classnames'

import { Button, MicroCta, Typography } from '@matillion/component-library'
import CopyIcon from 'components/Icons/CopyIcon'

import classes from './CopyToClipboard.module.scss'

interface CopyToClipboardProps {
  value: string
  onSuccess?: (value: string) => void
  onError?: (message: string) => void
  className?: string
  colour?: 'white' | 'black'
  as?: 'icon' | 'button'
}

export enum State {
  Copy = 'Copy to clipboard',
  Copied = 'Copied'
}

const CopyToClipboard = ({
  value,
  onSuccess,
  onError,
  className,
  colour = 'white',
  as = 'icon'
}: CopyToClipboardProps) => {
  const [labelState, setLabelState] = useState(State.Copy)

  const onCopyHandler = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setLabelState(State.Copied)
        onSuccess?.(value)
      })
      .catch((reason) => {
        const errorMessage =
          reason instanceof Error ? reason.message : 'Copy to clipboard failed'
        onError?.(errorMessage)
      })
  }
  const onMouseLeaveHandler = () => setLabelState(State.Copy)

  return as === 'icon' ? (
    <MicroCta
      className={classNames(classes.CopyToClipboardIcon, className)}
      alt="transparent"
      aria-label={labelState}
      onClick={onCopyHandler}
      onMouseLeave={onMouseLeaveHandler}
      data-testid="copy-to-clipboard"
      tooltipProps={{
        padding: 5
      }}
    >
      <CopyIcon colour={colour} />
    </MicroCta>
  ) : (
    <Button
      className={classNames(classes.CopyToClipboardBtn, className)}
      onClick={onCopyHandler}
      data-testid="copy-to-clipboard-btn"
    >
      <Typography format={'bcm'} weight={'bold'}>
        Copy
      </Typography>
      <CopyIcon colour={colour} />
    </Button>
  )
}

export default CopyToClipboard
