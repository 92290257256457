import { useCallback } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import CreateCredentialsForm, {
  type CreateCredentialsFormValues
} from './CreateCredentialsForm'

export interface CreateCredentialsFormWrapperProps {
  onSubmit: (values: CreateCredentialsFormValues) => Promise<void>
}

const DEFAULT_VALUES = {
  credentialsName: ''
}

const validationSchema = yup.object({
  credentialsName: yup
    .string()
    .max(255, 'The name must be less than 255 characters')
})

const CreateCredentialsFormWrapper = ({
  onSubmit
}: CreateCredentialsFormWrapperProps) => {
  const submitHandler = useCallback(
    async (values: CreateCredentialsFormValues) => onSubmit(values),
    [onSubmit]
  )

  return (
    <Formik<CreateCredentialsFormValues>
      initialValues={DEFAULT_VALUES}
      validateOnMount={true}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {(formikProps) => <CreateCredentialsForm {...formikProps} />}
    </Formik>
  )
}

export default CreateCredentialsFormWrapper
