import { Typography } from '@matillion/component-library'

import classes from './MemberProfile.module.scss'

interface MemberProfileProps {
  email: string
  firstName?: string
  lastName?: string
  icon?: string
}

const MemberProfile = ({
  firstName,
  lastName,
  email,
  icon
}: MemberProfileProps) => (
  <div
    className={classes.MemberProfile}
    data-testid="MembersList/Member/Profile"
  >
    <div role="presentation" className={classes.MemberProfile__Icon}>
      {icon && <img src={icon} alt="" />}
    </div>

    <div>
      {firstName || lastName ? (
        <>
          <Typography>
            {firstName} {lastName}
          </Typography>
          <Typography format="mc">{email}</Typography>
        </>
      ) : (
        <Typography>{email}</Typography>
      )}
    </div>
  </div>
)

export default MemberProfile
