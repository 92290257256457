import { Field } from '@matillion/component-library'
import type { FieldProps as FormikFieldProps } from 'formik'

const FieldGroup = ({
  field: { name, ...fieldProps },
  form: { touched, errors },
  ...otherProps
}: FormikFieldProps) => (
  <Field
    {...otherProps}
    {...fieldProps}
    errorText={touched[name] && (errors[name] as string)}
    name={name}
  />
)

export default FieldGroup
