import { useCallback } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import classnames from 'classnames'

import { Modal, Typography, Toaster } from '@matillion/component-library'

import useEditRequest from '../effects/mutations/useEditRequest'
import useMember from '../effects/useMember'
import type { Member } from '../types'

import UserForm, { UserFormValues } from '../UserForm'

import classes from '../Members.module.scss'

const getMemberName = (member?: Member) => {
  return member?.firstName && member.lastName
    ? `${member.firstName} ${member.lastName}`
    : member?.email
}

const EditMember = () => {
  const { makeToast } = Toaster.useToaster()
  const navigate = useNavigate()

  const [performEditRequest] = useEditRequest()

  const { id } = useParams<{ id: string }>()
  const member = useMember(id ?? '')

  const returnToMembersList = useCallback(() => {
    navigate('/members')
  }, [navigate])

  const handleEditMember = useCallback(
    async (values: UserFormValues) => {
      const memberName = getMemberName(member) ?? 'User'

      try {
        await performEditRequest(values)
        returnToMembersList()

        makeToast({
          title: `${memberName} edited successfully.`,
          message: `Your changes have been saved successfully. These changes will take effect from the next time ${memberName} logs in or refreshes their page.`,
          type: 'success'
        })
      } catch (e) {
        makeToast({
          title: 'Edit unsuccessful.',
          message: `There was a problem saving your changes to ${memberName}, please try again.`,
          type: 'error'
        })
      }
    },
    // Ignored temporarily to allow work in RELENG-73 to proceed. HUB-2396 created to actually deal with this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, performEditRequest, makeToast, returnToMembersList]
  )

  if (!member) {
    return <Navigate to="/members" replace />
  }

  return (
    <Modal
      ariaLabelledBy="Members/Edit/Title"
      data-testid="Members/Edit"
      className={classes['Modal--medium']}
      size="default"
      onCancel={returnToMembersList}
    >
      <Typography
        id="Members/Edit/Title"
        className={classnames(classes.Modal__Title, classes.Modal__Row)}
        weight="normal"
        format="tl"
        as="h2"
      >
        Edit User
      </Typography>

      <UserForm
        member={member}
        onSubmit={handleEditMember}
        onCancel={returnToMembersList}
      />
    </Modal>
  )
}

export default EditMember
