import { createElement, ElementType } from 'react'
import { useLocation, useMatch, Link, type LinkProps } from 'react-router-dom'
import { MenuItem } from '@matillion/component-library'

interface MenuNavLinkProps extends LinkProps {
  to: string
  as?: ElementType
  strict?: boolean
  [key: string]: unknown
}

const MenuNavLink = ({ as, to, children, ...otherProps }: MenuNavLinkProps) => {
  const match = useMatch({ path: to })
  const location = useLocation()
  const isActive = (to !== '/' && location.pathname.includes(to)) || match

  return createElement(
    as ?? MenuItem,
    {
      ...otherProps,
      as: Link,
      to,
      active: isActive,
      'data-testid': `MenuNavLink${isActive ? '/Active' : ''}`
    },
    children
  )
}

export default MenuNavLink
